import axiosInstance from "@/store/axios.instance";

export default {
    namespaced: true,
    state() {
        return {
            workSchedules: null,
        }
    },
    mutations: {
        setWorkSchedules(state, workSchedules) {
            if (workSchedules !== null)
                state.workSchedules = workSchedules
        },
        addWorkSchedule(state, workSchedule) {
            if (state.workSchedules === null) {
                state.workSchedules = []
            }
            if (workSchedule !== null) {
                state.workSchedules.push(workSchedule)
            }
        },
        removeWorkSchedule(state, id) {
            let workSchedules = state.workSchedules
            if (workSchedules !== null) {
                const indexOfWorkSchedule = workSchedules.findIndex(workSchedule => workSchedule.id === id)
                if (indexOfWorkSchedule > -1) {
                    workSchedules.splice(indexOfWorkSchedule, 1)
                }
            }
        }
    },
    actions: {
        fetchWorkSchedules({commit, rootGetters}) {
            return axiosInstance.get('/work-schedule', rootGetters['authModule/getUrlConfig'])
                .then((result) => {
                    commit('setWorkSchedules', result?.data)
                    return result?.data
                }, (error) => Promise.reject(error))
        },
        fetchWorkScheduleById({rootGetters}, id) {
            return axiosInstance.get('/work-schedule/' + id, rootGetters['authModule/getUrlConfig'])
                .then(result => result?.data)
        },
        updateWorkSchedule({rootGetters}, workSchedule) {
            return axiosInstance.put('/work-schedule/' + workSchedule.id, workSchedule, rootGetters['authModule/getUrlConfig']);
        },
        createWorkSchedule({commit, rootGetters}, workSchedule) {
            return axiosInstance.post('/work-schedule', workSchedule, rootGetters['authModule/getUrlConfig'])
                .then((result) => {
                    commit('addWorkSchedule', result?.data)
                    return result
                }, (error) => Promise.reject(error))
        },
        deleteWorkSchedule({commit, rootGetters}, workScheduleId) {
                return axiosInstance.delete('/work-schedule/' + workScheduleId, rootGetters['authModule/getUrlConfig'])
                    .then(() => {
                        commit('removeWorkSchedule', workScheduleId)
                    })
        }
    },
    getters: {
        getWorkSchedules(state) {
            return state.workSchedules ? state.workSchedules : []
        }
    }
}
