import axiosInstance, { addPlainTextHeader } from "@/store/axios.instance";

export default {
    namespaced: true,
    state() {
        return {
            teams: null,
            team: {
                teamClients: []
            },
            users: null,
            leaders: null,
        }
    },
    mutations: {
        setTeams(state, teams) {
            state.teams = teams
        },
        setTeam(state, team) {
            state.team = {
                ...team,
                teamClients: team?.teamClients || []
            }
        },
        setUsers(state, users) {
            state.users = users.forEach((user => {
                if (user.avatar) {
                    user.avatar += '?timeStopCache=' + Date.now()
                } else {
                    user.avatar = null
                }
            }))
        },
        setLeaders(state, leaders) {
            state.leaders = leaders.forEach((user => {
                if (user.avatar) {
                    user.avatar += '?timeStopCache=' + Date.now()
                } else {
                    user.avatar = null
                }
            }))
        },
    },

    actions: {
        fetchTeams({ commit, rootGetters }) {
            axiosInstance.get('/teams/organization', rootGetters['authModule/getUrlConfig'])
                .then(result => commit('setTeams', result?.data))
        },
        fetchTeam({ commit, rootGetters }, teamId) {
            return axiosInstance.get('/teams/' + teamId, rootGetters['authModule/getUrlConfig'])
                .then(response => {
                    commit('setTeam', response?.data)
                    return response?.data
                })
        },
        fetchTeamName({ rootGetters }, teamId) {
            return axiosInstance.get(`/teams/${teamId}/name`, rootGetters["authModule/getUrlConfig"])
                .then(result => result?.data)
        },
        createTeam({ dispatch, rootGetters }, team) {
            return axiosInstance.post('teams', team, rootGetters['authModule/getUrlConfig'])
                .then(result => {
                    dispatch('fetchNotArchivedTeamsOfOrganization')
                    return result?.data
                })
        },
        updateTeam({ dispatch, rootGetters }, team) {
            return axiosInstance.put('/teams', team, rootGetters['authModule/getUrlConfig'])
                .then(() => dispatch('fetchNotArchivedTeamsOfOrganization'))
        },
        updateTeamName({ rootGetters }, data) {
            return axiosInstance.put(`/teams/${data.teamId}/team-name`, data.teamName, addPlainTextHeader(rootGetters["authModule/getUrlConfig"]))
        },
        addUser({ dispatch, rootGetters }, data) {
            axiosInstance.put('teams/' + data.teamId + "/addUser/" + data.userId, null,
                rootGetters['authModule/getUrlConfig'])
                .then(() => {
                    dispatch('fetchUsersFromTeam', data.teamId)
                })
        },
        removeUser({ dispatch, rootGetters }, data) {
            return axiosInstance.put('teams/' + data.teamId + "/removeUser/" + data.userId, null,
                rootGetters['authModule/getUrlConfig'])
                .then(() => {
                    return dispatch('fetchUsersFromTeam', data.teamId)
                })
        },
        addLeader({ dispatch, rootGetters }, data) {
            axiosInstance.put('teams/' + data.teamId + "/addLeader/" + data.userId, null,
                rootGetters['authModule/getUrlConfig'])
                .then(() => {
                    dispatch('fetchLeadersFromTeam', data.teamId)
                })
        },
        toggleUserActiveInTeam({ commit, rootGetters }, data) {
            return axiosInstance.put('teams/' + data.teamId + "/toggleUserActive/" + data.userId, null, rootGetters['authModule/getUrlConfig'])
                .then((response) => {
                    commit('setTeam', response)
                    return response?.data
                })
        },
        removeLeader({ dispatch, rootGetters }, data) {
            return axiosInstance.put('teams/' + data.teamId + "/removeLeader/" + data.userId, null,
                rootGetters['authModule/getUrlConfig'])
                .then(() => {
                    return dispatch('fetchLeadersFromTeam', data.teamId)
                })
        },
        fetchClientsForTeam({ commit, rootGetters }, teamId) {
            return axiosInstance.get('teams/' + teamId + "/clients", rootGetters['authModule/getUrlConfig'])
                .then(result => commit('clientsModule/setClients', result?.data, { root: true }))
        },
        fetchClientForTeam({ commit, rootGetters }, data) {
            axiosInstance.get('teams/' + data.teamId + '/clients/' + data.clientId,
                rootGetters['authModule/getUrlConfig'])
                .then(result => commit('clientsModule/setClient', result?.data, { root: true }))
        },
        toggleClientActiveForTeam({ dispatch, rootGetters }, data) {
            axiosInstance.put('teams/' + data.teamId + '/clients/' + data.clientId + '/active', null,
                rootGetters['authModule/getUrlConfig'])
                .then(() => dispatch('fetchClientsForTeam', data.teamId))
        },
        toggleProjectActiveForTeam({ commit, rootGetters }, data) {
            axiosInstance.put('teams/' + data.teamId + '/projects/' + data.projectId + '/active', null,
                rootGetters['authModule/getUrlConfig'])
                .then(result => commit('projectsModule/updateProject', result?.data, { root: true }))
        },
        toggleTeamActive({ dispatch, rootGetters }, team) {
            team.active = !team.active
            return axiosInstance.put('/teams', team, rootGetters['authModule/getUrlConfig'])
                .then(() => dispatch('fetchNotArchivedTeamsOfOrganization'))
        },
        deleteTeam({ dispatch, rootGetters }, teamId) {
            axiosInstance.delete('/teams/' + teamId, rootGetters["authModule/getUrlConfig"])
                .then(() => dispatch('fetchNotArchivedTeamsOfOrganization'))
        },
        fetchNotArchivedTeams({ commit, rootGetters }) {
            axiosInstance.get('/teams/notArchived', rootGetters["authModule/getUrlConfig"])
                .then(result => commit('setTeams', result?.data))
        },
        fetchUsersFromTeam({ commit, rootGetters }, teamId) {
            return axiosInstance.get('/teams/' + teamId + '/users', rootGetters["authModule/getUrlConfig"])
                .then(result => {
                    commit('setUsers', result?.data)
                    return result?.data
                })
        },
        fetchLeadersFromTeam({ commit, rootGetters }, teamId) {
            return axiosInstance.get('/teams/' + teamId + '/leaders', rootGetters["authModule/getUrlConfig"])
                .then(result => {
                    commit('setLeaders', result?.data)
                    return result?.data
                })
        },
        syncTeamWithProjectManagement({ commit, dispatch, rootGetters }, teamId) {
            return axiosInstance.put('/teams/' + teamId + '/sync-with-project-management', null,
                rootGetters["authModule/getUrlConfig"])
                .then(result => {
                    commit('setTeam', result?.data)
                    return dispatch('fetchClientsForTeam', teamId)
                })
        },
        fetchNotArchivedTeamsOfOrganization({ commit, rootGetters }) {
            return axiosInstance.get('/teams/organization/notArchived', rootGetters["authModule/getUrlConfig"])
                .then(result => commit('setTeams', result?.data))
        },
        putNewProjectsInTeam({ commit, rootGetters }, data) {
            return axiosInstance.put('/teams/' + data.teamId + '/projects', data.newProjects, rootGetters["authModule/getUrlConfig"])
                .then(result => commit('setTeam', result.data))
        },
        deleteProjectInTeam({ commit, rootGetters }, data) {
            return axiosInstance.delete('/teams/' + data.teamId + '/client/' + data.clientId + '/project/' + data.projectId, rootGetters["authModule/getUrlConfig"])
                .then(result => commit('setTeam', result.data))
        },
        putNewLeadersInTeam({ rootGetters }, data) {
            return axiosInstance.put('/teams/' + data.teamId + '/leaders', data.newLeaders, rootGetters["authModule/getUrlConfig"])
                .then(result => result.data)
        },
        putNewMembersInTeam({ rootGetters }, data) {
            return axiosInstance.put('/teams/' + data.teamId + '/members', data.newMembers, rootGetters["authModule/getUrlConfig"])
                .then(result => result.data)
        },
        fetchNoTeamUsers({ rootGetters }, teamId) {
            return axiosInstance.get('/teams/' + teamId + '/excludedActiveUsers', rootGetters["authModule/getUrlConfig"])
                .then(result => result.data)
        },
        fetchTeamLeaders({ rootGetters }, teamId) {
            return axiosInstance.get('/teams/' + teamId + '/team-leaders', rootGetters["authModule/getUrlConfig"])
                .then(result => {
                    return result?.data
                })
        },
        removeTeamLeader({ rootGetters }, data) {
            return axiosInstance.delete('teams/' + data.teamId + "/removeTeamLeader/" + data.userId,
                rootGetters['authModule/getUrlConfig'])              
        },
        fetchTeamMembers({ rootGetters }, teamId) {
            return axiosInstance.get('/teams/' + teamId + '/team-members', rootGetters["authModule/getUrlConfig"])
                .then(result => {
                    return result?.data
                })
        },
        removeTeamMember({ rootGetters }, data) {
            return axiosInstance.delete('teams/' + data.teamId + "/removeTeamMember/" + data.userId,
                rootGetters['authModule/getUrlConfig'])              
        },

        
    }
}