<template>
  <custom-snackbar :value="displayBanner" :message=successMessage :type="BannerType.SUCCESS" :timeout="-1" @close="removeError" />
</template>

<script>
import BannerType from "@/shared/enums/bannerType";
import CustomSnackbar from "@/components/shared/snackbar/CustomSnackbar";

export default {
  name: "SuccessSnackbar",
  components: {CustomSnackbar},
  data() {
    return {
      BannerType: BannerType,
    }
  },
  methods: {
    removeError() {
      this.$store.commit('clearSuccessMessage')
    }
  },
  computed: {
    successMessage() {
      return this.$store.state.successMessage
    },
    displayBanner() {
      return !!this.successMessage
    }
  }
}
</script>
