import axiosInstance from "@/store/axios.instance"

export default {
    namespaced: true,
    state() {
        return {
            locationRegistrationConfig: {
              ciaoConfig: {
                ciaoEnabled: false
              }
            },
        }
    },
    mutations: {
        setLocationConfig(state, locationRegistrationConfig) {
            state.locationRegistrationConfig = locationRegistrationConfig
        }
    },
    actions: {
        fetchLocationConfig({commit, rootGetters}) {
            return axiosInstance.get('/location-registration-config', rootGetters["authModule/getUrlConfig"])
            .then(result =>{
                commit('setLocationConfig', result?.data)
                return result?.data
            })
        },
        updateLocationConfig({commit, rootGetters}, newLocationConfig) {
            return axiosInstance.put('/location-registration-config', newLocationConfig, rootGetters["authModule/getUrlConfig"])
            .then(result =>{
                commit('setLocationConfig', result?.data)
                return result?.data
            })
        }
    },
    getters: {
        getLocationConfig: state => state.locationRegistrationConfig
    }
}