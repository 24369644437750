import axiosInstance from "@/store/axios.instance";
import store from "@/store/index";

export default {
    namespaced: true,
    state() {
        return {
            sasKey: {
                expirationTime: null,
                key: null
            }
        }
    },
    mutations: {
        setSASKey(state, sasKey) {
            const urlParams = new URLSearchParams(sasKey)
            state.sasKey = {
                expirationTime: new Date(urlParams.get("se")),
                key: sasKey
            }
        }
    },
    actions: {
        requestExport({rootGetters}, exportRequest) {
            axiosInstance({
                              method: "post",
                              url: 'fileExport/export',
                              data: exportRequest,
                              headers: rootGetters['authModule/getUrlConfig'].headers,
                              responseType: "blob",
                          })
                .then((response) => {
                    const fileName = 'exportData' + new Date().toISOString() + '.xlsx'
                    const link = document.createElement('a');
                    // Tell the browser to associate the response data to
                    // the URL of the link we created above.
                    link.href = window.URL.createObjectURL(
                        new Blob([response?.data])
                    )
                    // Tell the browser to download, not render, the file.
                    link.setAttribute('download', fileName)
                    // Place the link in the DOM.
                    document.body.appendChild(link)
                    // Make the magic happen!
                    link.click();
                    URL.revokeObjectURL(link.href)
                })
                .catch(async (e) => store.dispatch('setError',await e.response.data.text()))
        },
        fetchSASKey({rootGetters, commit}) {
            return axiosInstance.get('fileExport/blob/sas', rootGetters['authModule/getUrlConfig'])
                .then(result => {
                    commit('setSASKey', result?.data)
                    return result?.data
                })
        }
    },
    getters: {
        getSASKey(state) {
            if (state.sasKey.expirationTime && state.sasKey.expirationTime > new Date()) {
                return null
            } else {
                return state.sasKey.key
            }
        }
    }
}