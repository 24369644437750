import './tracing'
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import VueGtag from "vue-gtag";
import routeNames from "@/router/RouteNames";
import {traceError} from "@/tracing";
import VueGtm from '@gtm-support/vue2-gtm';

// General documentation with examples and implementation: https://www.npmjs.com/package/@gtm-support/vue2-gtm?activeTab=readme
Vue.use(VueGtm, {
    id: [process.env.VUE_APP_GOOGLE_TAG_MANAGER_BUILDBASE_ID, process.env.VUE_APP_GOOGLE_TAG_MANAGER_SHAKEUP_ID],
    enabled: true,
    debug: false, // Whether or not display console logs debugs (optional)
    vueRouter: router,
    ignoredViews: [''], // Don't trigger events for specified router names (optional)
  });

Vue.config.productionTip = false

// append gtag for google analytics - https://matteo-gabriele.gitbook.io/vue-gtag/
Vue.use(VueGtag, {
    pageTrackerTemplate(to) {
        return {
            page_location: window.location.href,
            page_path: to.path,
            userId: store.getters["authModule/getUserId"],
            organizationId: store.getters["authModule/getOrganizationId"],
        }
    },
    pageTrackerExcludedRoutes: [routeNames.ROOT],
    config: {
        id: process.env.VUE_APP_GOOGLE_ANALYTICS_BUILDBASE_ID,
    },
    appName: "Buildbase"
}, router);

Vue.config.errorHandler = function (err, vm, info) {
    traceError(err, info)
}

const app = new Vue({
    vuetify,
    store,
    router,
    render: h => h(App)
})

export const gtag = app.$gtag

app.$mount('#app')