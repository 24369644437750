import axiosInstance from "@/store/axios.instance";

export default {
    namespaced: true,
    state() {
        return {
        }
    },
    actions: {
        fetchCiaoStatusesByUserIdAndDate({rootGetters}, payload) {
            return axiosInstance.get('ciao/statuses', {...rootGetters["authModule/getUrlConfig"], params: payload})
                .then(result => result?.data)
        }
    }
}