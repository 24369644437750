import axiosInstance from "@/store/axios.instance";

export default {
    namespaced: true,
    state() {
        return {
            genders: null,
            civilStatuses: null,
            nationalities: null
        }
    },
    mutations: {
        setGenders(state, genders) {
            state.genders = genders
        },
        setCivilStatuses(state, civilStatuses) {
            state.civilStatuses = civilStatuses
        },
        setNationalities(state, nationalities) {
            state.nationalities = nationalities
        }
    },
    actions: {
        fetchGenders({commit, rootGetters}) {
            return axiosInstance.get('/resources/genders', rootGetters["authModule/getUrlConfig"])
                .then((result) => {
                    commit('setGenders', result?.data)
                    return  result?.data
                })
        },
        fetchCivilStatuses({commit, rootGetters}) {
            return axiosInstance.get('/resources/civil-statuses', rootGetters["authModule/getUrlConfig"])
                .then((result) => {
                    commit('setCivilStatuses', result?.data)
                    return  result?.data
                })
        }
    },
    getters: {
        getGenders(state) {
            return state.genders
        },
        getCivilStatuses(state) {
            return state.civilStatuses
        },
        getNationalities(state) {
            return state.nationalities
        }
    }
}