<template>
  <v-snackbar app light top
              v-model="displaySnackBar"
              multi-line
              min-width="100"
              :timeout="timeout"
              :color="color"
              content-class="text-center px-2">
    {{ message }}
    <template v-slot:action="{ attrs }">
      <v-btn
          icon
          color="primary"
          v-bind="attrs"
          @click="close"
      >
        <v-icon>
          mdi-close
        </v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import BannerType from "@/shared/enums/bannerType";

export default {
  name: "CustomSnackbar",
  props: {
    value: {
      type: Boolean,
      required: true
    },
    message: {
      type: String
    },
    type: {
      type: String,
      required: true
    },
    timeout: {
      type: Number,
      default: 6000
    },
  },
  methods: {
    close() {
      this.$emit('input', false)
      this.$emit('close')
    }
  },
  computed: {
    color() {
      switch (this.type) {
        case BannerType.INFO:
          return 'banner-blue'
        case BannerType.SUCCESS:
          return 'banner-green'
        case BannerType.ERROR:
          return 'banner-red'
        case BannerType.WARNING:
          return 'banner-orange'
        default:
          return 'banner-blue'
      }
    },
    displaySnackBar: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue)
      }
    }
  }
}
</script>
