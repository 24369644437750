<template>
  <custom-snackbar :value="displayBanner" :message=errorMessage :type="BannerType.ERROR" :timeout="-1"
                   @close="removeError"/>
</template>

<script>
import BannerType from "@/shared/enums/bannerType";
import CustomSnackbar from "@/components/shared/snackbar/CustomSnackbar";

export default {
  name: "ApiErrorSnackbar",
  components: {CustomSnackbar},
  data() {
    return {
      BannerType: BannerType,
    }
  },
  methods: {
    removeError() {
      this.$store.commit('clearError')
    }
  },
  computed: {
    errorMessage() {
      return this.$store.state.error
    },
    displayBanner() {
      return !!this.errorMessage
    }
  }
}
</script>
