<template>
  <v-app>
    <api-error-snackbar/>
    <SuccessSnackbar />
    <router-view/>
  </v-app>
</template>

<script>
import ApiErrorSnackbar from "@/components/shared/snackbar/ApiErrorSnackbar";
import SuccessSnackbar from "@/components/shared/snackbar/SuccessSnackbar";

export default {
  name: 'App',
  components: {ApiErrorSnackbar, SuccessSnackbar},
  
  mounted() {
    this.$store.commit('clearSuccessMessage')
    this.$store.commit('clearError')
  },
  metaInfo() {
    return {
    meta: [
      { charset: 'utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'description', content: 'Buildbase, een ERP-software op maat voor bouw. Met modules zo als personeels-administratie, tijdsregistratie, klant- & projectbeheer' },
    ],
    link: [
      { rel: 'canonical', href: this.updatedCanonicalUrl },
    ]
    }
  },
  computed: {
    // Dynamically sets the url to the current page. Used for self-referential canonical tag.
    updatedCanonicalUrl(){
      return 'https://backoffice.buildbase.be' + this.$route.path
    },
  },
}
</script>