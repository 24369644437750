import axiosInstance from "@/store/axios.instance";

export default {
    namespaced: true,
    state() {
        return {
            invoiceConfig: null,
            nextNumbers: null
        }
    },
    mutations: {
        setInvoiceConfig(state, invoiceConfig) {
            state.invoiceConfig = invoiceConfig
        },
        setNextNumbers(state, nextNumbers) {
            state.nextNumbers = nextNumbers
        }
    },
    actions: {
        fetchInvoiceConfig({ commit, rootGetters }) {
            return axiosInstance.get('/invoice-config', rootGetters["authModule/getUrlConfig"])
                .then(result => {
                    commit('setInvoiceConfig', result?.data)
                    return result?.data
                })
        },
        updateInvoiceConfig({ commit, rootGetters }, invoiceConfig) {
            return axiosInstance.put('/invoice-config', invoiceConfig, rootGetters["authModule/getUrlConfig"])
                .then(result => {
                    commit('setInvoiceConfig', result?.data)
                    return result?.data
                })
        },
        fetchNextNumbers({ commit, rootGetters }, invoiceConfig) {
            return axiosInstance.post('/invoice-config/next-numbers', invoiceConfig, rootGetters["authModule/getUrlConfig"])
                .then(result => {
                    commit('setNextNumbers', result?.data)
                    return result?.data
                })
        },
    },
    getters: {
        getInvoiceConfig(state) {
            return state.invoiceConfig;
        },
        getNextNumbers(state) {
            return state.nextNumbers;
        }

    }

}