import axiosInstance from "@/store/axios.instance";

export default {
    namespaced: true,
    state() {
        return {
            dayResults: null,
            monthResult: null,
        }
    },
    mutations: {
        setDayResults(state, dayResults) {
            state.dayResults = dayResults
        },
        setMonthResult(state, monthResult) {
            state.monthResult = monthResult
        },
    },
    actions: {
        fetchDayResults({commit, rootGetters}, payload) {
            return axiosInstance.get(
                "/clockings/calendar/" + payload.startDate + "/" + payload.endDate + "/" + payload.userId,
                rootGetters['authModule/getUrlConfig'])
                .then(r => commit('setDayResults', r?.data))
                .catch(e => {
                    commit('setDayResults', null)
                    throw e
                })
        },
        fetchMonthResult({commit, rootGetters}, payload) {
            return axiosInstance.get(
                "/clockings/monthResult/" + payload.startDate + "/" + payload.endDate + "/" + payload.userId,
                rootGetters['authModule/getUrlConfig'])
                .then(result => commit('setMonthResult', result?.data))
        },
    },
    getters: {},
}