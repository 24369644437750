import axiosInstance from "@/store/axios.instance";
import ClockingType from "@/shared/constants/ClockingType";
import { getPosition } from '@/shared/utils/userUtils'
import {organizationHasModule} from "@/shared/utils/authenticationUtils";
import ModuleType from "@/shared/enums/moduleType";

export default {
    namespaced: true,
    state() {
        return {
            clockings: null,
            breakTime: false,
            breakLength: null,
            expectedHours: null,
            registeredHours: null,
            pricking: null
        }
    },
    mutations: {
        setClockings(state, clockings) {
            state.clockings = clockings
        },
        setBreakTime(state, breakTime) {
            state.breakTime = breakTime
        },
        setExpectedHours(state, hours) {
            state.expectedHours = hours
        },
        setRegisteredHours(state, hours) {
            state.registeredHours = hours
        },
        setBreakLength(state, breakLength) {
            state.breakLength = breakLength
        },
        setPricking(state, pricking) {
            state.pricking = pricking
        }
    },
    actions: {
        fetchDayOverview({commit, rootGetters}, payload) {
            return axiosInstance.get("/clockings/day_overview?date=" + payload.date + "&userId=" + payload.userId,
                rootGetters['authModule/getUrlConfig'])
                .then(result => {
                    commit('setClockings', result?.data.clockings)
                    commit('setBreakTime', result?.data.breakTime)
                    commit('setRegisteredHours', result?.data.registeredHours)
                    commit('setExpectedHours', result?.data.expectedHours)
                    commit('setBreakLength', result?.data.breakLength)
                    return result?.data
                })
        },
        createWorkClocking({dispatch, rootGetters}, clocking) {
            return axiosInstance.post("/clockings/work", clocking, rootGetters['authModule/getUrlConfig'])
                .then(result => {
                    //check if the result is a response or an error
                    if (result) {
                        dispatch('fetchDayOverview', {
                            date: clocking.day,
                            userId: clocking.userId
                        })
                    }
                    //return result, so it can be received in the calling component
                    return result
                })
        },
        createAbsenceClocking({dispatch, rootGetters}, clocking) {
            return axiosInstance.post("/clockings/absence", clocking, rootGetters['authModule/getUrlConfig'])
                .then(() => dispatch('fetchDayOverview', {
                    date: clocking.day,
                    userId: clocking.userId
                }))
        },
        createAbsenceClockings({dispatch, rootGetters}, payload) {
            return axiosInstance.post("/clockings/absence/" + payload.endDate, payload.absenceClocking,
                rootGetters['authModule/getUrlConfig'])
                .then(() => dispatch('fetchDayOverview', {
                    date: payload.absenceClocking.day,
                    userId: payload.absenceClocking.userId
                }))
        },
        updateWorkClocking({dispatch, rootGetters}, clocking) {
            return axiosInstance.put('/clockings/work', clocking, rootGetters['authModule/getUrlConfig'])
                .then(() => dispatch('fetchDayOverview', {
                    date: clocking.day,
                    userId: clocking.userId
                }))
        },
        updateAbsenceClocking({dispatch, rootGetters}, clocking) {
            return axiosInstance.put('/clockings/absence', clocking, rootGetters['authModule/getUrlConfig'])
                .then(() => dispatch('fetchDayOverview', {
                    date: clocking.day,
                    userId: clocking.userId
                }))
        },
        deleteClocking({rootGetters}, id) {
            return axiosInstance.delete('/clockings/' + id, rootGetters['authModule/getUrlConfig'])
        },
        async fetchClockingsForClient({rootGetters}, clientId) {
            const response = await axiosInstance.get('/clockings/client/' + clientId,
                rootGetters['authModule/getUrlConfig'])
            return response?.data
        },
        async fetchClockingsForProject({rootGetters}, payload) {
            const response = await axiosInstance.get('/clockings/project/' + payload.projectId + "/" + payload.clientId,
                rootGetters['authModule/getUrlConfig'])
            return response?.data
        },
        fetchPricking({commit, rootGetters}) {
            return axiosInstance.get('/clockings/temp-work', rootGetters['authModule/getUrlConfig'])
            .then(result => 
                {
                    commit('setPricking', result?.data)
                    return result?.data
                })
        },
        fetchStartTimePerWeekFilledNulls({rootGetters}, userId) {
            return axiosInstance.get('/clockings/' + userId + "/start-time-per-week", rootGetters['authModule/getUrlConfig'])
            .then(result => {  
                return result?.data 
            })
        },
        async createPricking({commit, rootGetters}, data) {
            // inject location in payload if CIAO enabled (both start and stop pricking)
            if (organizationHasModule([ModuleType.LOCATION_REGISTRATION])) {
              await getPosition().then((location) => {
                data = {
                  ...data,
                  clockingLocation: {
                    longitude: location?.coords.longitude,
                    latitude: location?.coords.latitude
                  }
                }
              }).catch((e) => console.log(e.message))
            }

            return axiosInstance.post('/clockings/temp-work', data, rootGetters['authModule/getUrlConfig'])
            .then(result => 
                {
                    if (result?.data.clockingType == ClockingType.TEMP_WORK) {
                        commit('setPricking', result?.data)
                    } else {
                        commit('setPricking', null)
                    }
                    return result?.data
                })
        },
        fetchClockingById({ rootGetters}, clockingId) {
            return axiosInstance.get(`clockings/${clockingId}`, rootGetters['authModule/getUrlConfig'])
            .then(result => result?.data)
        },
    },
    getters: {},
}