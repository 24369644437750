import {gtag} from "@/main"
import {createTrace} from "@/tracing";
import store from "@/store";

export const EventCategory = {
    LOAD: 'load',
    REPORTING: 'reporting',
}

export function analyticsEvent(eventName, eventCategory, eventValues) {
    const eventData = {
        event_category: eventCategory,
        event_label: document.title,
        ...eventValues,
        organizationId: store.getters["authModule/getOrganizationId"],
        userId: store.getters["authModule/getUserId"]
    }
    gtag.event(eventName, eventData)
}

export function conversionCompletedEvent(eventName) {
    gtag.event(eventName + "_completed")
    createTrace("buildbase-vue-payment", eventName + "_completed", {})
}

export function conversionFailedEvent(eventName) {
    gtag.event(eventName + "_failed")
    createTrace("buildbase-vue-payment", eventName + "_failed", {})
}