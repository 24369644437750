import store from "@/store";
import router from "@/router";

export async function asyncRouteActionIsEnabled(route) {
    // readout params from router definition
    const paramsOption = router.resolve(route).route.meta.paramsOption
    // when no parameter option is defined, route is enabled by default
    if (!paramsOption)
        return true
    // retrieve parameters
    let parameters = store.state.parameterModule.parameters;
    if (!parameters) {
        parameters = await store.dispatch('parameterModule/fetchParamsOrganization')
        if (!parameters)
            // every organization is expected to have parameters
            throw Error("No parameters found for organization")
    }
    // check if parameters contains param from router definition and if it is enabled
    const parameter = parameters.find(parameter => parameter.name === paramsOption)
    return parameter && parameter.value === "true"
}

export function routeActionIsEnabled(route) {
    // readout params from router definition
    const paramsOption = router.resolve(route).route.meta.paramsOption
    // when no parameter option is defined, route is enabled by default
    if (!paramsOption)
        return true
    // check if parameters contains param from router definition and if it is enabled
    const parameterValue = store.getters['parameterModule/getParameterValue'](paramsOption)
    return parameterValue === "true"
}