import axiosInstance from "@/store/axios.instance";

export default {
    namespaced: true,
    state() {
        return {
            packets: null,
            modules:null,
        }
    },
    mutations: {
        setPackets(state, packets) {
            state.packets = packets
        },
        setModules(state, modules) {
            state.modules = modules
        }
    },
    actions: {
        fetchActivePackets({commit}) {
            return axiosInstance.get('/subscriptionElement/subscribable/packets')
                .then(result => {
                    commit('setPackets', result.data)})

        },

        fetchActiveModules({commit,rootGetters}) {
            return axiosInstance.get('/subscriptionElement/subscribable/modules', rootGetters['authModule/getUrlConfig'])
                .then(result => {
                    commit('setModules', result.data)
                    return result.data
                })

        },

        seatsPaymentRequest({dispatch, rootGetters}, seatsPaymentRequest) {
            return axiosInstance.post('payment/seats', seatsPaymentRequest, rootGetters['authModule/getUrlConfig'])
                .then(result => {
                    dispatch('organizationModule/fetchSubscriptionStatus', null, {root: true})
                    return result?.data;
                })
                .catch(function (error) {
                    return Promise.reject(error);
                });
        },
        extendOrRenewalPaymentRequest({dispatch, rootGetters}, extendOrRenewalPaymentRequest) {
            return axiosInstance.post('payment/extension-renewal', extendOrRenewalPaymentRequest, rootGetters['authModule/getUrlConfig'])
                .then(result => {
                    dispatch('organizationModule/fetchSubscriptionStatus', null, {root: true})
                    return result?.data;
                })
                .catch(function (error) {
                    return Promise.reject(error);
                });
        },
        extendTrialToPaid({dispatch, rootGetters}, extendTrialToPaidRequest) {
            return axiosInstance.post('payment/trial-to-paid', extendTrialToPaidRequest, rootGetters['authModule/getUrlConfig'])
                .then(result => {
                    dispatch('organizationModule/fetchSubscriptionStatus', null, {root: true})
                    return result?.data;
                })
                .catch(function (error) {
                    return Promise.reject(error);
                });
        },
        calculateSubscriptionPriceExclVAT({rootGetters}, priceCalculationRequest) {
            return axiosInstance.post('payment/calculate-price/subscription', priceCalculationRequest, rootGetters['authModule/getUrlConfig'])
        },
        calculatePriceForExtraSeats({rootGetters}, extraSeats) {
            return axiosInstance.get('payment/calculate-price/extra-seats/' + extraSeats, rootGetters['authModule/getUrlConfig'])
        },
        calculatePriceForExtensionOrRenewal({rootGetters}, amountOfMonths) {
            return axiosInstance.get('payment/calculate-price/extension-renewal/' + amountOfMonths, rootGetters['authModule/getUrlConfig'])
        }
    },
}